.entry-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 20px 20px;
    height: auto;
}
.entry-page-logo{
    width: 280px;
}
.entry-socials{
    display: flex;
    gap: 8px;
    margin: 20px 0 30px;
}
.entry-page > p{
    font-size: 14px;
    font-weight: 300;
}

.entry-buttons{
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 14px;
    align-items: stretch;
    margin: 14px 0 30px;
}
.entry-select-lang{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.entry-select-lang span{
    display: flex;
    align-items: center;
    gap: 3px;
}
.entry-select-lang span b{
    font-size: 20px;
}
.title-img{
    position: relative;
    width: fit-content;
}
.title-img::after{
    content: '';
    width: 25px;
    height: 25px;
    position: absolute;
    background: url('../../assets/images/OPLUS_.png') no-repeat center/cover;
    right: 0;
    top: 50%;
    transform: translateY(-50%) translateX(calc(100% + 5px));

}
.play-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20.5px 0;
    border-radius: 16px;
    font-size: 20px;
    position: relative;
    overflow: hidden;
    background: rgb(255,125,201);
background: linear-gradient(180deg, rgba(255,125,201,1) 0%, rgba(255,0,156,1) 100%);
}
/* .play-btn::before{
    content: '';
    transform: translateY(-66%);
    background: rgb(255,125,201);
    background: radial-gradient(circle, rgba(255,125,201,1) 0%, rgba(255,0,156,1) 100%);
    width: 100%;
    top: 0;
    left: 0;
    height: 300%;
    position: absolute;
    display: block;
    z-index: -1;
} */
.entry-desc-container{
    position: relative;
    padding-top: 308px;
  }
.entry-img{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.entry-description-block{
    box-shadow: 0px 4px 150px black;
    width: 100%;
    background-color: white;
    border-radius: 20px;
    overflow: hidden;
    padding-bottom: 240px;
    background-image: url(./entry-desc-img-v2.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center bottom;
}
.entry-description-content{
    padding: 20px;
}
.entry-description-content h2{
    color: black;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 6px;
}
.entry-description-content p{
    font-size: 14px;
    color: #8A8A8A;
}
.entry-description-content hr{
    height: 2px;
    width: 100%;
    background-color: #E6E6E6;
    margin: 15px 0 20px;
}
/* .entry-description-block img{
    width: 100%;
    max-height: 270px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
} */