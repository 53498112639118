.tasks-list-wrapper{
    margin-top: 0;
}
.tasks-page{
    overflow: auto;
}
.task-card{
    align-items: center;
}
.task-card .boosters-list-item-desc{
    gap: 3px;
    height: auto;
}
.task-card .per-tap{
    font-size: 13px;
    margin: 4px 0;
    
}
.task-card .boosters-list-item-btn{
    padding: 7px;
}